






















































































































































































































































































































































































































.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}

.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}

.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}

.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
